import * as React from "react"
import { Link } from 'gatsby-plugin-react-i18next'
import {FiInfo} from "react-icons/fi"


const Badge = ({icon, iconColorClass, children, hover = true}) => {
    const Icon = icon ? icon : FiInfo 
    return(
        <div className="group flex flex-row my-2 w-fit">
            <div className={`bg-lang-btn-color  ${hover? "group-hover:bg-lang-btn-color-hover group-hover:text-icon_color-active" : ""} rounded-full p-3`}>
                <Icon className={`${iconColorClass} ${hover? "group-hover:text-icon_color-active" : ""} `} size={25} />
            </div>
            <div className={`text-xl font-light mt-3 ml-3 ${hover? "group-hover:text-icon_color-active" : ""}`}> {children} </div>
        </div>
    )
}


const BadgeLink = ({to, external = true, icon, iconColorClass, children}) => {
    
    if(to) {

        if(external) {

            return( 
                <a href={to} >
                   <Badge iconColorClass={iconColorClass} icon={icon}>{children}</Badge>
                </a>
            )

        } else {
            return( 
                <Link to={to}>
                   <Badge iconColorClass={iconColorClass} icon={icon}>{children}</Badge>
                </Link>
            ) 
        }

        return( 
            <Link to={to}>
               <Badge iconColorClass={iconColorClass} icon={icon}>{children}</Badge>
            </Link>
        )
    } 
    else {
        return( 
               <Badge hover={false} iconColorClass={iconColorClass} icon={icon}>{children}</Badge>
        )
    }

  
}

export default BadgeLink