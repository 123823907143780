import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PageHeader from "../components/page-header"
import {Link, useTranslation, useI18next, Trans} from "gatsby-plugin-react-i18next"
import {SiFreelancer, SiMaildotru} from "react-icons/si"
import BadgeLink from "../components/badge-link"
import SEO  from "../components/seo"


const Form = ({action, emailPlaceholder = "address@email",commentPlaceholder, sendBtn = "Send"}) => {
  return(
    <form className="flex flex-col gap-2" action={action} method="post" accept-charset="UTF-8" >

    <input className="p-2 text-home-sub focus:border-0 focus:outline-none" type="email" name="email" autocomplete="off" placeholder={emailPlaceholder} />
    <textarea className="p-2 text-home-sub focus:outline-none" placeholder={commentPlaceholder} name="comment" rows="10" cols="30" required></textarea>
    
    <input className="bg-icon_color-active text-white font-bold p-2" type="submit" value={sendBtn} />
  </form> 
  )
}

const ContactPage = ({data}) => {

  const {language} = useI18next()
  const { t } = useTranslation()

  
  const {contact_text , contact_links } = data.contactData

  //  <BadgeLink to={contact_links.fiverr} icon={SiFiverr} iconColorClass="text-white">Fiverr</BadgeLink>


  return (
    <Layout lightBackground>
      <SEO title={t('navigation.contact')} />
      <div className="container w-5/6 mx-auto lg:pl-10 pb-10"> 

              <PageHeader>
                {t('navigation.contact')}
              </PageHeader>

            <div className="flex flex-col lg:flex-row">
              <div className="w-fit lg:w-2/3">
                  
                  <div className="text-xl py-5">{contact_text[language]}</div>
                  
                  <div className="flex flex-col text-center lg:w-2/3 mx-auto mb-2">
                    <h2 className="font-extralight text-2xl mb-2">
                      Visit:
                    </h2>
                    <hr className="border-icon_color-active border-t-4" />
                  </div>
   

                  <div className="flex flex-col lg:flex-row place-content-between w-2/3 mx-auto">
                  <BadgeLink to={contact_links.useme} iconColorClass="text-pink-600">UseMe</BadgeLink>
                  <BadgeLink to={contact_links.freelancer} icon={SiFreelancer} iconColorClass="text-cyan-500">Freelancer.com</BadgeLink>
                 
                  
                  </div>


              </div>

              <div className="w-full lg:w-1/3 lg:px-10 px-1 mt-4">
                
                <h2 className="text-xl">Formularz Kontaktowy:</h2>
                <hr className="border-icon_color-active border-t-4" />
                <BadgeLink icon={SiMaildotru} iconColorClass="text-pink-600"> {contact_links.email}</BadgeLink>
                
                <Form action={contact_links.elformo_url} sendBtn={t('contact.send_btn')} emailPlaceholder={t('contact.form.email')} commentPlaceholder={t('contact.form.content')} />
              </div>

            </div>

      </div>


     


    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contactData : dataJson(contact_text: {pl: {ne: null}}) {
        contact_links {
          elformo_url
          email
          fiverr
          freelancer
          telegram
          useme
        }
        contact_text {
          en
          pl
        }
      }
  }
`;
